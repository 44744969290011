<template>
  <CCard class="mb-4">
    <CContainer class="container">

      <CRow>
        <CCol>
          <legend>Тестер</legend>
          <CForm>
            <div class="mb-3">
              <CFormLabel for="template">Шаблон сообщения</CFormLabel>
              <div class="textarea-container">
                <div class="backdrop">
                  <div id="template-highlights" class="highlights" v-html="highlightHtmlTemplate"></div>
                </div>
                <CFormTextarea
                  id="template"
                  v-model="template"
                  @input="setTemplate"
                  @scroll="changeScroll"
                />
              </div>
            </div>
            <div class="mb-3">
              <CFormLabel for="text">Пример сообщения</CFormLabel>
              <div class="textarea-container">
                <div class="backdrop">
                  <div id="text-highlights" class="highlights form-control" v-html="highlightHtmlText"></div>
                </div>
                <CFormTextarea
                  id="text"
                  v-model="text"
                  @input="setText"
                  :invalid="isTextValid != null && !isTextValid"
                  :valid="isTextValid && isTextValid"
                />
                <CFormFeedback invalid>
                  😱 Где-то закралась ошибка
                </CFormFeedback>
                <CFormFeedback valid>
                  🥳 Всё хорошо
                </CFormFeedback>
              </div>

            </div>
            <CLoadingButton
              :disabled-on-loading="true"
              :loading="isLoading"
              color="primary"
              @click="check">Проверить
            </CLoadingButton>
          </CForm>
        </CCol>
        <CCol>
          <legend>FAQ</legend>
          <li>
            %w - любой непрерывный набор букв и (или) спецсимволов
          </li>
          <li>
            %d - любой непрерывный набор цифр и (или) спецсимволов
          </li>
          <li>
            %w{1,n} - ограниченная последовательность слов (состоящих из букв, цифр или спецсимволов описанных ниже),
            разделенных пробелом, где n - число слов (должно быть не менее 1 и не более n).<br>
            При этом значение n в транзакционных шаблонах должно быть не более 10, значение n в
            сервисных шаблонах должно быть не более 20
            <CCallout color="info">
              Значение n должно быть не более 20! Операнд %w{1,1} использовать нельзя. Использовать только
              операнды от значения %w{1,2} (не менее 1, но не более 2 слов)
            </CCallout>
            Не допускается использование двух одинаковых операндов %w подряд. Например: %w{1,10}%w{1,12}
          </li>
          <li>
            %d+ - последовательность чисел (состоящая из цифр или спецсимволов описанных ниже), разделенных пробелом
            (или несколькими пробелами).
          </li>
          <li>
            %d{1,n} -ограниченная последовательность чисел (состоящая из цифр или спецсимволов описанных ниже),
            разделенных пробелом
            (или несколькими пробелами), где n - число чисел (должно быть не менее 1 и не более n).
            <CCallout color="info">
              Значение n должно быть не более 20! Операнд %d{1,1} использовать нельзя. Использовать только
              операнды от значения %d{1,2} (не менее 1, но не более 2 слов)
            </CCallout>

            Набор букв или цифр %w и %d может содержать в себе знаки препинания и спецсимволы: !№#%.,:;\/()+-"_'`&^?{}[]&lt;>|!@$=~*
          </li>

        </CCol>
      </CRow>
    </CContainer>

  </CCard>

</template>

<script>
import {checkTester} from "../../services/api";

export default {
  name: "Tester",
  data() {
    return {
      template: 'Карта %d: %d %d покупка на сумму %d KZT. %w{1,5} выполнена успешно. Доступно %d KZT.',
      // template: 'Покупка в кредит:\n' +
      //   '%d{1,4} ₸\n' +
      //   '%w{1,9}\n' +
      //   'Платеж: %d{1,4} ₸\n' +
      //   'Первый платеж до %w{1,9}\n' +
      //   'Срок кредита: %d',
      text: 'Карта ***1234: 01.01.21 10:00:00 покупка на сумму 1000 KZT. Магазин Жаксы выполнена успешно. Доступно 100000 KZT.',
      // text: 'Покупка в кредит:\n' +
      //   '15 000 ₸\n' +
      //   'ИП Марвин\n' +
      //   'Платеж: 5000 ₸\n' +
      //   'Первый платеж до 16 мая\n' +
      //   'Срок кредита 3 месяца',
      isTextValid: null,
      isLoading: false,
      highlightHtmlText: "",
      highlightHtmlTemplate: ""
    }
  },
  mounted() {
    document.getElementById("template").addEventListener('scroll', this.changeScroll)
    document.getElementById("text").addEventListener('scroll', this.changeScroll)
  },
  methods: {
    changeScroll(e) {
      if (e.target.id === "template") {
        document.getElementById("template-highlights").scrollTop=document.getElementById("template").scrollTop;
      }
      if (e.target.id === "text") {
        document.getElementById("text-highlights").scrollTop=document.getElementById("text").scrollTop;
      }
    },
    setText(event) {
      this.text = event.target.value
      this.isTextValid = null
      this.highlightHtmlText = this.applyHighlights(0)
      this.highlightHtmlTemplate = this.applyHighlights(0)
    },
    applyHighlights(index, text) {
      // https://codersblock.com/blog/highlight-text-inside-a-textarea/
      if (index === 0) {
        return this.text
      }
      const regex = new RegExp("^.{1,"+index+"}", "gms");

      return text
        .replace(regex, '<mark>$&</mark>');
    },
    setTemplate(event) {
      this.template = event.target.value
      this.isTextValid = null
    },
    check() {
      this.isLoading = true
      checkTester(this.template, this.text).then(response => {
        this.isLoading = false
        this.isTextValid = response.data[0].result.message === 'ok'
        if (this.isTextValid) {
          this.highlightHtmlText = this.applyHighlights(0)
          this.highlightHtmlTemplate = this.applyHighlights(0)
        } else {
          this.highlightHtmlText = this.applyHighlights(response.data[0].result.mark.text-1, this.text)
          this.highlightHtmlTemplate = this.applyHighlights(response.data[0].result.mark.template-1, this.template)
          document.getElementById("template").scrollTop=0
          document.getElementById("text").scrollTop=0
        }
      })
      .catch(e=>{
        this.isLoading = false
        this.isTextValid = false
      })
    },
    showNew() {
      this.visibleEditWindow = true
      this.visibleEditWindowIsNew = true
    },
    showEdit() {
      this.visibleEditWindow = true
      this.visibleEditWindowIsNew = false
    }
  }
}
</script>

<style scoped>
legend {
  padding-top: 10pt;
}

.nav-export div {
  top: 0.3em;
  right: 0.5em;
}

.container {
  padding-bottom: 30pt;
}
.backdrop, textarea {
  width: 460px;
  height: 180px;
}
.highlights, textarea {
  padding: 0.375rem 0.75rem;
  padding-right: calc(1.5em + 0.75rem);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.backdrop {
  position: absolute;

  pointer-events: none;
  transition: transform 1s;
}

.highlights {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: transparent;
  background-color: transparent;
  border-color: transparent;
  height: 100%;
  overflow-y: scroll;
}

.textarea-container textarea {
  display: block;
  z-index: 2;
  margin: 0;
  overflow: auto;
  resize: none;
  transition: transform 1s;
}

.highlights ::v-deep(mark) {
  border-radius: 3px;
  background-color: var(--cui-success);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  /*color: transparent;*/
  padding: 0.375rem 0;
  color: var(--cui-input-color, rgba(44, 56, 74, 0.95));
}

.textarea-container {
  display: block;
  margin: 0 auto;
  transform: translateZ(0);
  -webkit-text-size-adjust: none;
}
</style>
